<script>
export default {
  locales: {
    pt: {
      'CODE': 'CÓDIGO',
      'INVESTMENT': 'INVESTIMENTO',
      'PLAN': 'PLANO',
      'PROFIT': 'RENDIMENTO',
      'PERCENT': 'PORCENTAGEM',
      'ACTIVE': 'ATIVO',
      'RENEW': 'RENOVAR',
    },
    es: {
      'CODE': 'CÓDIGO',
      'INVESTMENT': 'INVERSIÓN',
      'PLAN': 'PLANO',
      'PROFIT': 'RENDIMIENTO',
      'PERCENT': 'PORCENTAJE',
      'ACTIVE': 'ATIVO',
      'RENEW': 'RENOVAR',
    }
  },
  props: {
    code: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    earnings: {
      type: String,
      default: ""
    },
    percent: {
      type: String,
      default: "0"
    },
    profile: {
      type: String,
      default: "300%"
    },
    plan: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body pt-2 pb-2">
      <div class="row justify-content-center text-center">
        <div class="col-6 col-md-3 pt-2">
          <small class="text-muted mb-0">{{ t('STATUS') }}</small>
          <p class="mb-0 notranslate">
            <span v-if="status === 'active'" class="badge badge-pill badge-soft-success font-size-12">{{ t('ACTIVE') }}</span>
            <span v-else-if="status === 'renew'" class="badge badge-pill badge-soft-danger font-size-12">{{ t('RENEW') }}</span>
          </p>
        </div>
        <div class="col-6 col-md-3 pt-2">
          <small class="text-muted mb-0">{{ t('PLAN') }}</small>
          <p class="mb-0 notranslate text-uppercase">{{ plan }}</p>
        </div>
        <div class="col-6 col-md-3 pt-2">
          <small class="text-muted mb-0">{{ t('PROFIT') }}</small>
          <p class="mb-0 notranslate">{{ earnings | currency }}</p>
        </div>
        <div class="col-6 col-md-3 pt-2">
          <small class="text-muted mb-0">{{ t('PERCENT') }}</small>
          <p class="mb-0 text-success notranslate">{{ percent * 3 }}% - {{ profile }}</p>
        </div>
      </div>
      <b-progress class="mt-3" style="border-radius: 3px;" :value="Number(percent)" variant="success" height="15px"></b-progress>
      <div class="mb-2 font-size-11 text-center">
        {{ percent * 3 }}%
      </div>
    </div>
  </div>
</template>