<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Match': 'Equiparação',
      'Triple Match': 'Tripla Equiparação',
      'Points': 'Pontos',
      'Match Info': 'Informações do Equiparação',
      'STATUS': 'STATUS',
      'QUALIFIED': 'QUALIFICADO',
      'UNQUALIFIED': 'NÃO QUALIFICADO',
      'LEFT TEAM': 'EQUIPE ESQUERDA',
      'RIGHT TEAM': 'EQUIPE DIREITA',
      'PAIR': 'PAR',
      'FORECAST': 'PREVISÃO',
      'Configuration': 'Configuração',
      'Side': 'Lado',
      'Left': 'Esquerdo',
      'Right': 'Direito',
      'Change': 'Alterar',
      'Success': 'Sucesso',
      'The side has been changed.': 'O lado foi alterado.'
    },
    es: {
      'Match': 'Equiparação',
      'Triple Match': 'Tripla Equiparação',
      'Points': 'Puntos',
      'Match Info': 'Información Equiparação',
      'STATUS': 'ESTADO',
      'QUALIFIED': 'CALIFICADO',
      'UNQUALIFIED': 'NO CUALIFICADO',
      'LEFT TEAM': 'EQUIPO IZQUIERDA',
      'RIGHT TEAM': 'EQUIPO DERECHA',
      'PAIR': 'PAR',
      'FORECAST': 'PREVISIÓN',
      'Configuration': 'Configuración',
      'Side': 'Lado',
      'Left': 'Izquierdo',
      'Right': 'Derecho',
      'Change': 'Cambiar',
      'Success': 'Éxito',
      'The side has been changed.': 'El lado se ha cambiado.',
    }
  },
  components: {  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      alert: {
        binary: { type: '', message: '' },
      },

      binaryInfo: {
        loading: true,
        errored: false,
        empty: false,
        status: '',
        team: {
          left: '0',
          right: '0',
        },
        points: {
          left: '0',
          right: '0',
        },
        pair: '0.00',
        bonus: '0.00',
      },

      binaryConfig: {
        selected: '',
        options: {
          'l': 'Left',
          'r': 'Right'
        },
        side: 'l'
      },

      triple: {
        left: 0,
        right: 0,
      }
    }
  },
  validations: {
    binary: {},
  },
  methods: {
    getBinaryInfo() {
      api
        .get('network/binary/'+this.account.user.id)
        .then(response => {
          if (response.data.status=='success') {
            this.binaryInfo.status = response.data.points.status
            this.binaryInfo.team.left = response.data.users.left
            this.binaryInfo.team.right = response.data.users.right
            this.binaryInfo.points.left = response.data.points.current.left
            this.binaryInfo.points.right = response.data.points.current.right
            this.binaryInfo.pair = response.data.points.pair
            this.binaryInfo.bonus = response.data.points.bonus
          } else {
            this.binaryInfo.status = 'UNQUALIFIED'
          }
        })
        .catch(error => {
          this.binaryInfo.errored = error
          this.binaryInfo.loading = false
        })
        .finally(() => {
          this.binaryInfo.errored = false
          this.binaryInfo.loading = false
        })
    },
    getBinarySide() {
      api
        .get('network/binary/side')
        .then(response => {
          if (response.data.status=='success') {
            this.binaryConfig.side = response.data.side
            this.binaryConfig.selected = response.data.side
          }
        })
    },
    binaryConfigSubmit() {
      this.$v.binary.$touch();
      if (this.$v.binary.$invalid) {
        return;
      } else {
        api
          .post('network/binary/side',{
            side: this.binaryConfig.side
          })
          .then(response => {
            if (response.data.status=='success') {
              Swal.fire({
                title: this.t('Success'),
                text: this.t(response.data.message),
                icon: 'success',
                confirmButtonColor: '#34c38f',
                confirmButtonText: 'OK'
              })
            }
          })
      }
    },
    getTripleMatch() {
      api
        .get('network/triplematch')
        .then(response => {
          if (response.data.status=='success') {
            this.triple.left = response.data.points.left
            this.triple.right = response.data.points.right
          }
        })
    }
  },
  mounted() {
    this.getBinaryInfo()
    this.getBinarySide()
    this.getTripleMatch()
  }
};
</script>

<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header bg-soft-success rounded-top">
          <h5 class="m-0 text-dark text-center">
            {{ t('Match') }}
          </h5>
        </div>
        <div class="card-body pb-0 border2">
          <div class="row">
            <div class="col-xl-4 pb-3">
              <div class="row text-center">
                <div class="col-xl-12 col-md-5">
                  <h5 class="font-size-13 mb-1">{{ t('STATUS') }}</h5>
                  <p v-if="this.binaryInfo.status === 'QUALIFIED'" class="border border-success p-2 notranslate rounded font-weight-bold text-success mb-2" style="line-height:15px;">{{ t('QUALIFIED') }}</p>
                  <p v-else class="border border-danger p-2 notranslate rounded font-weight-bold text-danger mb-2" style="line-height:15px;">{{ t('UNQUALIFIED') }}</p>
                </div>
                <div class="col-xl-12 col-md-3">
                  <h5 class="font-size-13 mb-1">{{ t('PAIR') }}</h5>
                  <p class="border border-dark p-2 notranslate rounded font-weight-bold mb-2" style="line-height:15px;">{{ this.binaryInfo.pair | currency }}</p>
                </div>
                <div class="col-xl-12 col-md-4">
                  <h5 class="font-size-13 mb-1">{{ t('FORECAST') }}</h5>
                  <p class="border border-success text-success p-2 notranslate rounded font-weight-bold mb-2" style="line-height:15px;">{{ this.binaryInfo.bonus | currency }}</p>
                </div>
              </div>
            </div>
            <div class="col-xl-8 pt-xl-3">
              <div class="row">
                <div class="col-12 col-md-6 pb-4">
                  <div class="pt-3 text-center border border-success rounded">
                    <h5 class="text-success mb-3 font-size-16">{{ t('LEFT TEAM') }} <i class="mdi mdi-arrow-left-bold"></i></h5>
                    <div class="row">
                      <div class="col-6 col-xl-12">
                        <h5 class="mb-0 notranslate">{{ this.binaryInfo.team.left }}</h5>
                        <h6 class="mb-3">{{ t('Team') }}</h6>
                      </div>
                      <div class="col-6 col-xl-12">
                        <h5 class="mb-0 notranslate">{{ this.binaryInfo.points.left }}</h5>
                        <h6 class="mb-3">{{ t('Points') }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 pb-4">
                  <div class="pt-3 text-center border border-success rounded">
                    <h5 class="text-success mb-3 font-size-16"><i class="mdi mdi-arrow-right-bold"></i> {{ t('RIGHT TEAM') }}</h5>
                    <div class="row">
                      <div class="col-6 col-xl-12">
                        <h5 class="mb-0 notranslate">{{ this.binaryInfo.team.right }}</h5>
                        <h6 class="mb-3">{{ t('Team') }}</h6>
                      </div>
                      <div class="col-6 col-xl-12">
                        <h5 class="mb-0 notranslate">{{ this.binaryInfo.points.right }}</h5>
                        <h6 class="mb-3">{{ t('Points') }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6">
      <div class="card card-h-fix">
        <div class="card-header bg-soft-success rounded-top">
          <h5 class="m-0 text-dark text-center">
            {{ t('Triple Match') }}
          </h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6 py-5 text-center border-right">
              <h3 class="m-0">{{ triple.left | points }}</h3>
              <h5 class="m-0 text-uppercase font-size-14">{{ t('Left') }}</h5>
            </div>
            <div class="col-6 py-5 text-center">
              <h3 class="m-0">{{ triple.right | points }}</h3>
              <h5 class="m-0 text-uppercase font-size-14">{{ t('Right') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6">
      <div class="card card-h-fix">
        <div class="card-header bg-soft-success rounded-top">
          <h5 class="m-0 text-dark text-center">
            {{ t('Configuration') }}
          </h5>
        </div>
        <div class="card-body">
          <div v-if="alert.binary.message" :class="'alert ' + alert.binary.type">{{alert.binary.message}}</div>
          <b-form @submit.prevent="binaryConfigSubmit" class="form-leg">
            <div class="d-block">
              <div class="leg-radio" v-for="(option,index) in binaryConfig.options" :key="index">
                <input type="radio" :id="index" :value="index" v-model="binaryConfig.side" :checked="binaryConfig.side == index">
                <label :for="index">{{ t(option) }}</label>
              </div>
            </div>
            <b-button type="submit" variant="success" class="w-lg btn-lg">{{ t('Change') }}</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>